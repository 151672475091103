<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.STORE.REALIZATION') }}
            </h3>
          </div>
          <div class="card-button">
            <v-row justify="center">
              <v-btn
                to="/realisations/create"
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('MENU.STORE.REALIZATION') }} +
              </v-btn>
            </v-row>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="list"
            :loading="isLoading"
            loading-text="Malumot yuklanmoqda..."
            hide-default-footer
            no-data-text="Malumot kiritilmagan"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                list
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <Action :index="item"></Action> </template
          ></v-data-table>
          <div class="text-left my-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="10"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getCount / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'

export default {
  mixins: [validationMixin],
  validations: {
    combobox: { required },
    ContractByClientInput: { required }
  },
  data() {
    return {
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      start_modal: false,
      end_modal: false,
      client_contract: false,
      combobox: '',
      dialog: false,
      end_dialog: false,
      perPage: 10,
      currentPage: 1,
      ContractByClientInput: '',
      singleSelect: true,
      selected: []
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.TYPE'),
          value: 'placement_type_name'
        },
        {
          text: this.$t('TABLE_HEADER.DATE'),
          value: 'oper_date'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRAGENT'),
          value: 'contragent_name'
        },
        {
          text: this.$t('TABLE_HEADER.CON_CONTRAGENT'),
          value: 'contragent_contract_number'
        },
        {
          text: this.$t('TABLE_HEADER.AMOUNT'),
          value: 'get_doc_products_total.total'
        },
        {
          text: this.$t('TABLE_HEADER.NDSAMOUNT'),
          value: 'get_doc_products_total.nds_summa'
        },
        {
          text: this.$t('TABLE_HEADER.NDS_AMOUNT'),
          value: 'get_doc_products_total.total_withnds_summa'
        },
        {
          value: 'action',
          text: '',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.realizations.results

      return data
    },
    getCount() {
      return this.$store.state.requests.realizations.count
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      const data = {
        page: value,
        operation_type: 'REAL'
      }
      this.$store.dispatch('getRealisations', data)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STORE.TITLE') },
      { title: this.$t('MENU.STORE.REALIZATION') }
    ])
  },
  components: {
    Action
  }
}
</script>
